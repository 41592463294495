import { Branch } from "mkm-types";
import { Steps, StepType } from "./branchTrayTypes";

export interface UseBranchTrayState {
  currentStep: StepType;
  nearestBranches: Branch[] | null;
  selectedTrayBranch: Branch | null;
}

const useBranchTray = () => {
  const state = useState<UseBranchTrayState>(`useBranchTray`, () => ({
    currentStep: Steps.details,
    nearestBranches: [],
    selectedTrayBranch: null,
  }));

  const updateSteps = (step: StepType) => {
    state.value.currentStep = step;
  };

  return {
    updateSteps,
    ...toRefs(state.value),
  };
};

export default useBranchTray;
