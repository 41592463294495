import { Branch } from "mkm-types";

export enum Steps {
  details = "details",
  search = "search",
  nearest = "nearest",
  selected = "selected",
}

export type StepType = Steps.details | Steps.search | Steps.nearest | Steps.selected;

export const steps: Record<string, StepType> = {
  details: Steps.details,
  search: Steps.search,
  nearest: Steps.nearest,
  selectedTrayBranch: Steps.selected,
};

export type BranchTrayDetailsProps = {
  branch: Branch | null;
};
